import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { Schemat } from '../../schemat'
import { RectangleBounds, Point } from '../../schemat-externals'
import { Router } from "@angular/router";
import { SchematService } from 'src/app/service/schemat.service';
import { SchematSummary } from 'src/app/schemat-read-util';

@Component({
  selector: 'app-allschemats',
  templateUrl: './allschemats.component.html',
  styleUrls: ['./allschemats.component.css']
})
export class AllschematsComponent implements OnInit {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    private router: Router,
    private schematService: SchematService) {

    this.matIconRegistry.addSvgIcon(
      "diagram2",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/diagram2.svg")
    );
  }

  schemactSummaries: SchematSummary[] = [{ id: null, name: "New" }]

  onClickSchemact(schemact: Schemat) {
    var id: String
    if (schemact.id == null) {
      let change = this.schematService.createSchematChange()
      //TODO manage this - move to crete SchemactCreate
      change.viewPort = new RectangleBounds(0, 0, 400, 300)
      change.diagramWidth = 800
      change.diagramHeight = 600
      change.toolbarTopLeft = new Point(40, 30)
      change.name = "schemaT" + this.schemactSummaries.length
      change.version = "0"
      // add in general x
      const simpleTypesId = "simpleTypes"
      const generalxId = "_General"
      change.imports = [simpleTypesId, generalxId]
      this.schematService.change(change)
      const schematService = this.schematService
      this.schematService.retrieveByIdResolveImports(simpleTypesId).then(
        (simpleTypesSchema) => {
          id = change.id
          //preload the import
          const s = this.schematService.getById(id)
          s.imports = [simpleTypesSchema]          
        }
      ).then( () => {
        this.schematService.retrieveByIdResolveImports(generalxId).then(
        (generalSchema) => {
          id = change.id
          //preload the import
          const s = this.schematService.getById(id)
          s.imports.push(generalSchema)         
          this.router.navigate(['editor', id])
        }
         ) } ) 
      

    } else {
      id = schemact.id
      this.schematService.retrieveByIdResolveImports(id).then(
        (searchResult) => {
          searchResult.viewPort.width = searchResult.diagramWidth
          searchResult.viewPort.height = searchResult.diagramHeight
          this.router.navigate(['editor', id])
        }
      )
    }
  }

  updateSchemas(schemacts: SchematSummary[]) {
    this.schemactSummaries = schemacts.filter((s) => s.childId == null)
    this.schemactSummaries.unshift({ id: null, name: "New" })
  }

  ngOnInit() {
    this.schematService.retrieveByLoggedInUser().then(
      (result) => this.updateSchemas(result)
    )
  }


}
