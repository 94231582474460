import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';
import { Schemat, SchematChange, ChangeAction } from 'src/app/schemat';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-schemat-form',
  templateUrl: './schemat-form.component.html',
  styleUrls: ['./schemat-form.component.css']
})
export class SchematFormComponent implements OnInit {

  id: String;
  schemat: Schemat;
  formGroup: FormGroup;
  titleAlert: string = 'This field is required';
  possibleSchemats : Schemat[];
  currentImports : Schemat[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder) { }
    

  isCurrentImport(s: Schemat, id: String): boolean {
     return s.imports.filter((i)=>{return i.id==id}).length>0
  }

  ngOnInit() {
    this.currentImports = []
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.schemat = this.schematService.getById(this.id)
      this.createForm();

      this.schematService.retrieveByLoggedInUser().then(summaries=> {
        this.possibleSchemats = summaries.filter( (s) => {
           return s.childId!=null || s.name=='simpleTypes' || s.id.startsWith('_')} )  as Schemat[];
        this.possibleSchemats.forEach( (s) => {
             if (this.isCurrentImport(this.schemat, s.id)) {
               this.currentImports.push(s);
             }
        }  ) 
      }).then(()=> {
        this.createForm();
        this.setChangeValidate();  
      })

    });
  }

  setChangeValidate() {
   }
 
   createForm() {


     this.formGroup = this.formBuilder.group({
       'name': [this.schemat.name, Validators.required],
       'version': [this.schemat.version, Validators.required],
       'imports': [this.currentImports, Validators.nullValidator]

     });
   }
 
   get name() {
    return this.formGroup.get('name') as FormControl
  }

  get version() {
    return this.formGroup.get('version') as FormControl
  }

  getImportsValue() : Schemat[] {
    return this.formGroup.value['imports'] as Schemat[]
  }

  addImport() {
    if (this.possibleSchemats.length>0) {
        const existingImports = (this.schemat.imports==null) ? [] : this.schemat.imports;
        existingImports.push(this.possibleSchemats[0]);
        this.schemat.imports = existingImports;
    }
  }

  deleteImport(index: number) {
  this.schemat.imports.splice(index, 1)
  }



  importsHaveChanged() : boolean {
    const selected = this.getImportsValue()
      if (this.schemat.imports.length!=selected.length) {
               return true
      }
      for (var i = 0; i<selected.length; i++ ) {
         if (!this.isCurrentImport(this.schemat, selected[i].id)) {
              return true
         }
      } 

      return false;
  }

  apply() : boolean {

    const change: SchematChange = { id: this.id, _a:ChangeAction.Update } 
    var changed = false;

    const newversion = this.formGroup.value['version']
    const newname = this.formGroup.value['name']
    if (this.schemat.name != newname /*|| this.schemat.version != newversion*/ ) {
        change.name = newname;
        changed = true 
    }
    if (this.schemat.version != newversion /*|| this.schemat.version != newversion*/ ) {
      change.version = newversion;
      changed = true 
  }
  if (changed ) {
      this.schematService.change(change) 
    } 

  if (this.importsHaveChanged()) {
     const selectedIds = this.getImportsValue().map((i)=>{return i.id})
     const change: SchematChange = { id: this.id,  _a:ChangeAction.Update,  imports: selectedIds}
     this.schematService.change(change) 
      return true
  } else {
    return false
  }

  }

  back() {
    this.router.navigate(['editor', this.id ])
  }

  save() {
    const reloadRequired = this.apply()
    if (reloadRequired) {
      this.schematService.retrieveById(this.id).then(
        (importedSchemat) => {
               this.back();
               console.log("loaded import: ", importedSchemat);
              }
      );
    } else {
       this.back()
    } 
  }

  async snapshot() {
    await this.schematService.createsnapshot(this.id, this.formGroup.value['version']).then(
      snapshotId => {
        console.log("snapshot snapshotId:", snapshotId)
        this.router.navigate(['all' ])
        //this.id=snapshotId
      }
    )
  }


  cancel() {
     this.back()
  }


}
